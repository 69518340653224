import { NavItem } from '@/components/layout/nav-item'
import { buildYoutubeUrl, checkEnv } from '../lib/utils/utils'
import { getLangCode } from '@/components/i18n/lang-context'

export const SITE_IS_PROD = !process.env.NEXT_PUBLIC_SITE_IS_PROD || process.env.NEXT_PUBLIC_SITE_IS_PROD !== 'true' ? false : true
export const SITE_IS_JBDA = checkEnv(process.env.NEXT_PUBLIC_SITE, 'NEXT_PUBLIC_SITE') === 'jbda'
export const SITE_IS_LB = checkEnv(process.env.NEXT_PUBLIC_SITE, 'NEXT_PUBLIC_SITE') === 'lb'

const JBDA_NAV_ITEMS: NavItem[] = []

const LB_NAV_ITEMS: NavItem[] = [
  { title: { en: 'How it works', fr: 'Comment ça marche' }, href: '/#howItWorks' },
  { title: { en: 'For whom?', fr: 'Pour qui ?'}, subs: [
    { title: { en: 'Crypto Owners', fr: 'Détenteurs de Crypto' }, href: '/landing/targets/crypto' },
    { title: { en: 'Regulated Professionnals', fr: 'Professions Reglementées' }, href: '/landing/targets/regulated' },
    { title: { en: 'Real Estate Professionnals', fr: 'Professionnels de l\'Immobilier' }, href: '/landing/targets/immo' }
  ]},
  { title: { en: 'Blog', fr: 'Blog' }, href: '/blog' },
  { title: { en: 'About Us', fr: 'A Propos de Nous' }, href: '/about-us' }
]

/**
 * Site Config for JBDA SITE
 */
export const siteConfigJBDA = {
  appName: 'JBDA',
  descriptionHtml: 'JBDA : Simplifier l’investissement crypto dans l’économie réelle. - Sécurisez Vos Transactions Immobilières et Mobilières en investissant sereinement vos cryptomonnaies dans l\'économie réelle.',
  links: {
    linkedin: 'https://www.linkedin.com/company/jbda-associ%C3%A9s',
    linkedinId: '104058917',
    twitterHandle: '',
    youtubeChannel: '',
    youtubeChannelId: ''
  },
  logo: {
    app: '/jbda/JBDA_logo_picto_couleur_rvb.png',
    appWhite: '/jbda/JBDA_logo_picto_blanc_rvb.png',
    vertical: '/jbda/JBDA_logo_vertical_couleur_rvb.png',
    verticalWhite: '/jbda/JBDA_logo_vertical_blanc_rvb.png',
    verticalBj: '/jbda/JBDA_logo_vertical_couleur_rvb.png',
    horizontal: '/jbda/JBDA_logo_horizontal_couleur_rvb.png',
    horizontalWhite: '/jbda/JBDA_logo_horizontal_blanc_rvb.png',
    horizontalBj: '/jbda/JBDA_logo_horizontal_couleur_rvb.png',
    horizontalBjRatio2_1: '/jbda/JBDA_logo_horizontal_couleur_rvb_ratio_2_1.png',
    horizontalBjWhite: '/jbda/JBDA_logo_horizontal_blanc_rvb.png',
  },
  mail: {
    subjectPreffix: '[JBDA]'
  },
  consentCookie:'jbda-consent',
  navItems: JBDA_NAV_ITEMS
}

/**
 * Site Config for LB SITE
 */
export const siteConfigLB = {
  appName: 'Legibloq',
  descriptionHtml: 'Legibloq : Sécurisez Vos Transactions Immobilières et Mobilières en investissant sereinement vos cryptomonnaies dans l\'économie réelle.',
  links: {
    linkedin: 'https://www.linkedin.com/showcase/legibloq',
    linkedinId: '104058954',
    twitterHandle: 'Legibloq',
    youtubeChannel: 'Legibloq_by_JBDA',
    youtubeChannelId: 'UCsqifW2qz6hXYPjXnthkkhg'
  },
  logo: {
    app: '/lb/Legibloq_logo_picto_couleur_rvb.png',
    appWhite: '/lb/Legibloq_logo_picto_blanc_rvb.png',
    vertical: '/lb/Legibloq_logo_vertical_bj_couleur_rvb.png',
    verticalWhite: '/lb/Legibloq_logo_vertical_bj_blanc_rvb.png',
    verticalBj: '/lb/Legibloq_logo_vertical_bj_couleur_rvb.png',
    horizontal: '/lb/Legibloq_logo_horizontal_couleur_rvb.png',
    horizontalWhite: '/lb/Legibloq_logo_horizontal_blanc_rvb.png',
    horizontalBj: '/lb/Legibloq_logo_horizontal_bj_couleur_rvb.png',
    horizontalBjRatio2_1: '/lb/Legibloq_logo_horizontal_bj_couleur_rvb_ratio_2_1.png',
    horizontalBjWhite: '/lb/Legibloq_logo_horizontal_bj_blanc_rvb.png',
  },
  mail: {
    subjectPreffix: '[Legibloq]'
  },
  consentCookie:'lb-consent',
  navItems: LB_NAV_ITEMS
}

// Select the SITE_CONFIG based on the SITE env var
const SITE_CONFIG = SITE_IS_JBDA ? siteConfigJBDA : siteConfigLB

/**
 * Site Config
 */
const SITE_URL = checkEnv(process.env.NEXT_PUBLIC_SITE_URL, 'NEXT_PUBLIC_SITE_URL') as string
export const siteConfig = {
  appId: 'jbda-lb',
  appName: SITE_CONFIG.appName,
  descriptionHtml: SITE_CONFIG.descriptionHtml,
  links: {
    github: 'https://github.com/jarvix-src/jvx-template',
    linkedin: SITE_CONFIG.links.linkedin,
    linkedinId: SITE_CONFIG.links.linkedinId,
    twitterHandle: SITE_CONFIG.links.twitterHandle,
    youtubeChannel: SITE_CONFIG.links.youtubeChannel,
    youtubeChannelId: SITE_CONFIG.links.youtubeChannelId,
    jarvix: 'https://www.jarvix.com',
    bda: 'https://bruzzodubucq.com',
    site: SITE_URL,
    jbda: process.env.NEXT_PUBLIC_SITE_JBDA_URL || (SITE_IS_JBDA ? SITE_URL : '/jbda'),
    legibloq: process.env.NEXT_PUBLIC_SITE_LB_URL || (SITE_IS_LB ? SITE_URL : '/lb')
  },
  logo: {
    app: SITE_CONFIG.logo.app,
    appWhite: SITE_CONFIG.logo.appWhite,
    vertical: SITE_CONFIG.logo.vertical,
    verticalWhite: SITE_CONFIG.logo.verticalWhite,
    horizontal: SITE_CONFIG.logo.horizontal,
    horizontalWhite: SITE_CONFIG.logo.horizontalWhite,
    horizontalBj: SITE_CONFIG.logo.horizontalBj,
    horizontalBjRatio2_1: SITE_CONFIG.logo.horizontalBjRatio2_1,
    horizontalBjWhite: SITE_CONFIG.logo.horizontalBjWhite,
    jarvix: '/jarvix_logo_264x264_transparent.png',
    jarvixVertical: '/jarvix_logo_rvb_1a_vertical_couleur.png',
    jarvix64: '/_next/image?url=%2Fjarvix_logo_264x264_transparent.png&w=64&q=75',
    bdVertical: '/logo-bruzzodubucq.png'
  },
  mail: {
    subjectPreffix: SITE_CONFIG.mail.subjectPreffix,
    contactAddress: process.env.NEXT_PUBLIC_CONTACT_ADDRESS ||'contact@jbda.io',
    privacyAddress: process.env.NEXT_PUBLIC_PRIVACY_ADDRESS ||'contact+privacy@jbda.io',
    jarvixContactAddress: 'contact@jarvix.com'
  },
  google: {
    gtmId: process.env.NEXT_PUBLIC_GOOGLE_GTM_ID,
    gaId: process.env.NEXT_PUBLIC_GOOGLE_GA_ID
  },
  consentCookie: SITE_CONFIG.consentCookie,
  navItems: SITE_CONFIG.navItems,
  phone: '+33 (0)7 49 05 35 47'
}

export const getVideoUrl = (embedded:boolean = true) => {
  const HOW_TO_YOUTUBE_VIDEO_EN = process.env.NEXT_PUBLIC_YOUTUBE_VIDEO_EN
  const HOW_TO_YOUTUBE_VIDEO_FR = process.env.NEXT_PUBLIC_YOUTUBE_VIDEO_FR
  switch (getLangCode()) {
    case 'en':
      return !HOW_TO_YOUTUBE_VIDEO_EN ? null : buildYoutubeUrl(HOW_TO_YOUTUBE_VIDEO_EN, embedded)
    case 'fr':
      return !HOW_TO_YOUTUBE_VIDEO_FR ? null : buildYoutubeUrl(HOW_TO_YOUTUBE_VIDEO_FR, embedded)
    default:
      return null
  }
}
export const getKoalendar = () => {
  const KOALENDAR_EN = process.env.NEXT_PUBLIC_KOALENDAR_EN
  const KOALENDAR_FR = process.env.NEXT_PUBLIC_KOALENDAR_FR
  switch (getLangCode()) {
    case 'en':
      return KOALENDAR_EN
    case 'fr':
      return KOALENDAR_FR
    default:
      return KOALENDAR_EN
  }
}
export const getKoalendarUrl = () => {
  const koalendar = getKoalendar()
  return !koalendar ? null : 'https://koalendar.com/e/' + koalendar + '?embed=true'
}